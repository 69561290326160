<template>
    <li>
        <a href="javascript:void(0)">
            <picture>
                <img :src="jobPlaceholder.image" alt="" v-if="jobPlaceholder.image != null">
                <img src="../assets/images/christin-hume-Hcfwew744z4-unsplash.jpg" alt="" v-else>
            </picture>
            <div class="caption">
                <p>{{ jobPlaceholder.title }}</p>
                <!-- <b-icon icon="box-arrow-in-up-right"></b-icon> -->
            </div>
        </a>
    </li>
</template>
<script>
export default {
    props: {
        job: {}
    },
    data() {
        return {
            jobPlaceholder: {
                title: 'Lorem ipsum',
                image: null
            }
        }
    },
    methods: {
        showDetailChild() {
            this.$emit('showDetail', this.job)
        }
    },
    created() {

        if (this.job.link_image)
            this.jobPlaceholder.image = this.job.link_image

        if (this.job.title)
            this.jobPlaceholder.title = this.job.title

    }
}

</script>
<style scoped lang="scss">
@import "../assets/scss/global";


ul {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content:  center;
    li {
        position: relative;
        /* display: block; */
        overflow: hidden;
        width: 100%;
        max-width: 400px;

        picture {
            /* width: 400px;
            height: 207px;
            position: relative;
            display: block;
            overflow: hidden;
            cursor: context-menu;
            
            @media(max-width: 1180px){
                width: 380px;
            }

            @media (max-width: $mobile) {
                width: 360px;
                max-height: 360px;
            } */

            img {
                /* position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0; */
                transition: all .7s ease;
                transform: scale(1.15);
                //filter: sepia(0);
            }
        }

        .caption {


            background-color: rgba($cor2, .7);
            z-index: 1;
            width: 390px;
            position: absolute;
            bottom: 0px;
            left: 5px;
            right: 0px;
            padding: 5px 5px;
            transition: all .5s ease;
            transform: translateY(15px);
            opacity: 0;
            display: flex;
            text-align: center;
            font-weight: bold;
            justify-content: space-between;
            align-items: flex-start;
            border-radius: 6px;
            cursor: context-menu;
            
            
            @media (max-width: $mobile) {
                max-width: 100%;
                width: auto;
            }

            p {
                color: $cor1;
                font-size: 18px;
                width: 100%;
                padding-right: 10px;
                text-align: center;
            }

            .b-icon {
                color: transparent;
                fill: transparent;
                cursor: context-menu;
                svg {
                }
            }
        }

        &:hover {
            img {
                transform: scale(1.17);
                //filter: sepia(100%);
                cursor: default;
            }

            .caption {
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }
}
</style>
